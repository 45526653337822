<svg
  width="72"
  height="40"
  viewBox="0 0 72 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x="0.65"
    y="0.65"
    width="38.7"
    height="38.7"
    rx="7.35"
    fill="#28A9FF"
    stroke="white"
    stroke-width="1.3"
  />
  <path
    d="M24.9223 10C24.3245 10 23.8415 10.483 23.8415 11.0808V12.7864H16.1645V11.0808C16.1645 10.483 15.6815 10 15.0838 10C14.486 10 14 10.483 14 11.0808V16.9132V28.8317C14 29.4294 14.483 29.9125 15.0808 29.9125C15.6785 29.9125 16.1615 29.4294 16.1615 28.8317V27.126H23.8385V28.8317C23.8385 29.4294 24.3215 29.9125 24.9192 29.9125C25.517 29.9125 26 29.4294 26 28.8317V23.0023V11.0808C26 10.483 25.517 10 24.9192 10H24.9223ZM21.8823 24.9645H16.1645V20.5449C16.7472 20.8619 17.4174 21.04 18.1268 21.04H21.8823C22.966 21.04 23.8445 21.9185 23.8445 23.0023C23.8445 24.086 22.966 24.9645 21.8823 24.9645ZM23.8415 19.3706C23.2589 19.0536 22.5887 18.8755 21.8792 18.8755H18.1238C17.04 18.8755 16.1615 17.997 16.1615 16.9132C16.1615 15.8294 17.04 14.9509 18.1238 14.9509H23.8415V19.3706Z"
    fill="white"
  />
  <g clip-path="url(#clip0_5574_179627)">
    <mask
      id="mask0_5574_179627"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="32"
      y="0"
      width="40"
      height="40"
    >
      <path
        d="M64 0H40C35.5817 0 32 3.58172 32 8V32C32 36.4183 35.5817 40 40 40H64C68.4183 40 72 36.4183 72 32V8C72 3.58172 68.4183 0 64 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_5574_179627)">
      <path d="M72 0H32V31.01H72V0Z" fill="url(#paint0_linear_5574_179627)" />
      <path d="M72 29.9399H32V39.9999H72V29.9399Z" fill="#6D3F00" />
      <path
        d="M63.02 26.5H40.98C36.02 26.5 32 27.89 32 29.6V30.29C32 31.99 36.02 33.38 40.98 33.38H63.01C67.97 33.38 71.99 32 71.99 30.29V29.6C71.99 27.89 67.97 26.51 63.01 26.51L63.02 26.5Z"
        fill="#845318"
      />
      <path
        d="M52.8602 27.2502C52.8602 27.2502 52.7602 23.6902 53.6602 23.6002C54.5602 23.5202 55.2002 24.6102 57.6702 24.3902C60.9502 24.0902 63.1502 20.9602 63.7002 17.9502C63.7002 17.9502 62.4402 18.9602 60.9902 19.1702C59.3002 19.4102 57.6402 18.6902 55.5202 19.4302C53.9602 19.9702 53.1202 21.9302 53.0102 23.3902C53.0102 23.3902 52.3002 24.8002 52.3102 27.0802L52.8702 27.2602L52.8602 27.2502Z"
        fill="#8CC914"
      />
      <path
        d="M52.9999 23.3799C52.9999 23.3799 52.2899 24.7899 52.2999 27.0699H52.5399C52.5399 27.0699 52.6599 24.6699 53.0099 23.3799"
        fill="#6A9512"
      />
      <path
        d="M51.9498 29.6702C51.9498 29.6702 52.2198 27.9002 52.2198 26.8002C52.2198 25.7002 52.3098 23.0502 50.6498 23.2202C49.7098 23.3102 46.7198 23.4402 44.8198 21.9402C42.5598 20.1602 42.1398 16.7002 39.5298 15.7402C39.5298 15.7402 44.7998 14.5202 48.7298 16.8902C51.1698 18.3602 51.5798 20.1202 51.5798 22.7002C51.5798 22.7002 52.3398 23.3802 52.6198 24.6802C52.8798 25.8802 53.0798 27.6302 53.0698 29.6802H51.9598L51.9498 29.6702Z"
        fill="#8CC914"
      />
      <path
        d="M50.6499 23.2199C50.6499 23.2199 51.9699 22.8899 52.2899 24.8999C52.4899 26.1099 52.4499 27.7599 52.2899 29.6699H51.9499C51.9499 29.6699 52.2199 27.8999 52.2199 26.7999C52.2199 25.6999 52.3099 23.0499 50.6499 23.2199Z"
        fill="#6A9512"
      />
      <path
        d="M51.2701 22.7198C50.1701 20.5598 48.2701 19.0398 46.0601 18.0698C48.4501 18.5098 50.6801 20.3198 51.2701 22.7198Z"
        fill="#6A9512"
      />
      <path
        d="M53.3301 23.3802C53.9201 21.5102 56.1801 20.6802 58.0001 20.9902C56.1901 21.2702 54.4301 21.8302 53.3301 23.3802Z"
        fill="#6A9512"
      />
    </g>
  </g>
  <rect
    x="32.65"
    y="0.65"
    width="38.7"
    height="38.7"
    rx="7.35"
    stroke="white"
    stroke-width="1.3"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5574_179627"
      x1="52"
      y1="0"
      x2="52"
      y2="21.53"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#D7FF8F" />
      <stop offset="1" stop-color="#FAFF8E" />
    </linearGradient>
    <clipPath id="clip0_5574_179627">
      <rect width="40" height="40" fill="white" transform="translate(32)" />
    </clipPath>
  </defs>
</svg>
