import { _ } from 'svelte-i18n'

let t: (
  key: string,
  interpolation?: {
    values: Record<string, string>
  },
) => string

_.subscribe((_t) => {
  t = _t
})

export { t }
