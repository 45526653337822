<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16.9199 6.9989C16.9199 4.0829 15.1829 1.9989 11.9199 1.9989C8.65692 1.9989 6.91992 4.0829 6.91992 6.9989L6.91992 9.9989C6.91992 12.9149 8.65692 14.9989 11.9199 14.9989C12.0159 14.9989 12.7959 14.9989 12.9199 14.9989C14.2529 14.9989 14.2529 12.9989 12.9199 12.9989C12.7879 12.9989 11.8569 12.9989 11.8569 12.9989C9.83693 12.9739 8.91992 11.8619 8.91992 9.9989L8.91992 6.9989C8.91992 5.1149 9.84992 3.9989 11.9199 3.9989C13.9899 3.9989 14.9199 5.1149 14.9199 6.9989L14.9199 7.9989C14.9199 8.5509 15.3679 8.9989 15.9199 8.9989C16.4719 8.9989 16.9199 8.5509 16.9199 7.9989L16.9199 6.9989ZM16.9199 13.9989C16.9199 12.5779 16.5179 11.4209 15.7639 10.5609C14.8389 9.5069 13.4689 8.9989 11.9199 8.9989C11.7909 8.9989 11.6239 8.9989 10.9199 8.9989C10.3679 8.9989 9.91992 9.4469 9.91992 9.9989C9.91992 10.5509 10.3679 10.9989 10.9199 10.9989C11.6239 10.9989 11.7909 10.9989 11.9199 10.9989C12.9369 10.9989 13.7599 11.2999 14.2639 11.8739C14.6809 12.3489 14.9199 13.0419 14.9199 13.9989L14.9199 16.9989C14.9199 19.0689 13.8039 19.9989 11.9199 19.9989C10.0359 19.9989 8.91992 19.0689 8.91992 16.9989L8.91992 15.9989C8.91992 15.4469 8.47192 14.9989 7.91992 14.9989C7.36792 14.9989 6.91992 15.4469 6.91992 15.9989L6.91992 16.9989C6.91992 20.2619 9.00392 21.9989 11.9199 21.9989C14.8359 21.9989 16.9199 20.2619 16.9199 16.9989L16.9199 13.9989Z"
    fill="#5A697E"
  />
</svg>
