import { t } from '@/lib/translate'
import { isStandalone } from '@/params'
import { dialog as dialogStore } from '@/stores'
import { z } from 'zod'

export type Dialog = {
  type: 'default' | 'warning'
  name: string
  titleKey?: string
  contentKey?: string
  primaryBtnKey: string
  secondaryBtnKey?: string
  primaryBtnAction?: string
  secondaryBtnAction?: string
}

const DialogActionMessage = z.object({
  type: z.literal('dialog_action'),
  payload: z.object({
    action: z.string(),
  }),
})

export function showDialog(
  dialog: Dialog,
  onAction?: (action: string) => void,
) {
  if (isStandalone) {
    dialogStore.set(dialog)
  } else {
    window.postMessage({
      type: 'show_dialog',
      payload: dialog,
    })
  }
  if (onAction) {
    const handler = (event: MessageEvent) => {
      const { payload } = DialogActionMessage.parse(event.data)
      onAction?.(payload.action)
      window.removeEventListener('message', handler)
    }
    window.addEventListener('message', handler)
  }
}

export function dispatchDialogAction(action: string) {
  window.postMessage({
    type: 'dialog_action',
    payload: { action },
  })
}

const DialogButtonAction = {
  Confirm: 'confirm',
  Cancel: 'cancel',
  Retry: 'retry',
} as const

export const Dialog = {
  UnhandledError(message: string) {
    return {
      type: 'default',
      name: 'unhandled_error',
      titleKey: 'Unhandled Error',
      contentKey: message,
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  UnhandledHTTPError(code: number) {
    return {
      type: 'default',
      name: 'unhandled_http_error',
      titleKey: 'Unhandled HTTP Error',
      contentKey: `Code: ${code}`,
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get InternetDisconnected() {
    return {
      type: 'default',
      name: 'internet_disconnected',
      titleKey: t('dialog_no_internet_title'),
      contentKey: t('dialog_no_internet_content'),
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get WrongVerificationCode() {
    return {
      type: 'default',
      name: 'verification_error',
      titleKey: t('dialog_wrong_verification_code_title'),
      contentKey: t('dialog_wrong_verification_code_content'),
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get WrongPassword() {
    return {
      type: 'default',
      name: 'wrong_password',
      titleKey: t('dialog_wrong_password_title'),
      contentKey: t('dialog_wrong_password_content'),
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get WrongOriginalPassword() {
    return {
      type: 'default',
      name: 'wrong_old_password',
      titleKey: t('dialog_wrong_original_password_title'),
      contentKey: t('dialog_wrong_original_password_content'),
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get SignOut() {
    return {
      type: 'warning',
      name: 'logout',
      titleKey: t('dialog_manage_account_log_out_title'),
      contentKey: t('dialog_manage_account_log_out_content'),
      primaryBtnKey: t('btn_log_out'),
      secondaryBtnKey: t('btn_cancel'),
      primaryBtnAction: DialogButtonAction.Confirm,
      secondaryBtnAction: DialogButtonAction.Cancel,
    } satisfies Dialog
  },
  get EmailUsed() {
    return {
      type: 'default',
      name: 'used_email',
      titleKey: t('dialog_email_used_title'),
      contentKey: t('dialog_email_used_content'),
      primaryBtnKey: t('btn_got_it'),
    } satisfies Dialog
  },
  get GuestDataOverwritingWarning() {
    return {
      type: 'warning',
      name: 'guest_data_overwriting_warning',
      titleKey: t('dialog_visitor_login_existing_account_title'),
      contentKey: t(
        'dialog_visitor_login_existing_account_content_no_subscription',
      ),
      primaryBtnKey: t('btn_discard_signin'),
      secondaryBtnKey: t('btn_cancel'),
      primaryBtnAction: DialogButtonAction.Confirm,
      secondaryBtnAction: DialogButtonAction.Cancel,
    } satisfies Dialog
  },
  get AgeBlocking() {
    return {
      type: 'default',
      name: 'age_blocked',
      titleKey: t('dialog_error_occurred_title'),
      contentKey: t('dialog_age_invalid_context_china'),
      primaryBtnKey: t('OK'),
    } satisfies Dialog
  },
  get LegacyUserFound() {
    return {
      type: 'default',
      name: 'legacy_user_found',
      titleKey: t('dialog_third_party_conflict_title'),
      contentKey: t('dialog_third_party_conflict_content'),
      primaryBtnKey: t('OK'),
    } satisfies Dialog
  },
  get RetryDialog() {
    return {
      type: 'default',
      name: 'retry',
      titleKey: t('dialog_error_occurred_title'),
      contentKey: t('dialog_error_occurred_content'),
      primaryBtnKey: t('retry'),
      primaryBtnAction: DialogButtonAction.Retry,
    } satisfies Dialog
  },
  get ForestSignOut() {
    return {
      type: 'default',
      name: 'forest_logout',
      titleKey: t('settings_logout_title'),
      contentKey: t('settings_logout_message'),
      primaryBtnKey: t('btn_log_out'),
      secondaryBtnKey: t('btn_cancel'),
      primaryBtnAction: DialogButtonAction.Confirm,
      secondaryBtnAction: DialogButtonAction.Cancel,
    } satisfies Dialog
  },
  get WrongServer() {
    return {
      type: 'default',
      name: 'wrong_server',
      titleKey: t('dialog_error_wrongserver_title'),
      contentKey: t('dialog_error_wrongserver_contnet'),
      primaryBtnKey: t('OK'),
    } satisfies Dialog
  },
  get SignUpDisabled() {
    return {
      type: 'default',
      name: 'signup_disabled',
      titleKey: t('signin_not_user_dialog_title'),
      contentKey: t('signin_not_user_dialog_content'),
      primaryBtnKey: t('register_btn'),
      primaryBtnAction: DialogButtonAction.Confirm,
    } satisfies Dialog
  },
  Bind(params: {
    legacyName: string
    loginInfo: string
    totalMinutes: number
  }) {
    return {
      type: 'default',
      name: 'dialog_confirm_connection_title',
      titleKey: t('dialog_confirm_connection_title'),
      contentKey: t('dialog_confirm_connection_content', {
        values: {
          total_minute_legacy: params.totalMinutes.toString(),
          name_legacy: params.legacyName,
          logininfo: params.loginInfo,
        },
      }),
      primaryBtnKey: t('btn_confirm'),
      secondaryBtnKey: t('btn_cancel'),
      primaryBtnAction: DialogButtonAction.Confirm,
      secondaryBtnAction: DialogButtonAction.Cancel,
    } satisfies Dialog
  },
  BindingOverride(params: {
    legacyName: string
    boundUsername: string
    legacyTotalMinutes: number
    boundUserTotalMinutes: number
  }) {
    return {
      type: 'default',
      name: 'dialog_confirm_connection_replace_title',
      titleKey: t('dialog_confirm_connection_replace_title'),
      contentKey: t('dialog_confirm_connection_replace_content', {
        values: {
          name: params.boundUsername,
          name_legacy: params.legacyName,
          total_minute_legacy: params.legacyTotalMinutes.toString(),
          total_minute: params.boundUserTotalMinutes.toString(),
        },
      }),
      primaryBtnKey: t('btn_confirm'),
      secondaryBtnKey: t('btn_cancel'),
      primaryBtnAction: DialogButtonAction.Confirm,
      secondaryBtnAction: DialogButtonAction.Cancel,
    } satisfies Dialog
  },
  get GoogleSignInUnavailable() {
    return {
      type: 'default',
      name: 'google_signin_unavailable',
      titleKey: t('dialog_update_required_title'),
      contentKey: t('dialog_update_required_content'),
      primaryBtnKey: t('btn_update'),
      primaryBtnAction: DialogButtonAction.Confirm,
    } satisfies Dialog
  },
}
