<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import BindIcon from '@/components/icons/bind.svelte'
  import ErrorIcon from '@/components/icons/error.svelte'
  import Forest from '@/components/icons/forest.svelte'
  import Link from '@/components/icons/link.svelte'
  import Seekrtech from '@/components/icons/seekrtech.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import { bindLegacyUser } from '@/lib/api'
  import { showIntercomMessenger } from '@/lib/intercom'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import type { User } from '@/types/record'
  import { router } from '@inertiajs/svelte'
  import { _ } from 'svelte-i18n'

  export let user: User
  export let toBindLegacyUser: {
    name: string
    total_minutes: number
  }
  export let boundUser: null | {
    name: string
    total_minutes: number
  }

  const loginInfo =
    user.ssoProvider === 'apple'
      ? $_('apple')
      : user.ssoProvider === 'google'
        ? $_('google', { values: { email: user.email } })
        : user.email

  let isLoading = false
  async function bind() {
    isLoading = true
    const [result, payload] = await bindLegacyUser()
    isLoading = false
    switch (result) {
      case 'SUCCESS':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/bind_success', {
            ...payload,
            bound_user_name: toBindLegacyUser.name,
            bound_user_total_minutes: toBindLegacyUser.total_minutes,
          }).toString(),
          navBarTitle: '',
          navBarVisible: true,
        })
        break
      case 'ALREADY_BOUND':
        location.replace(location.origin)
        dispatchWebViewAction({ action: 'end_session' })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }

  function confirmBinding() {
    const dialog = Dialog.Bind({
      loginInfo,
      legacyName: toBindLegacyUser.name,
      totalMinutes: toBindLegacyUser.total_minutes,
    })
    showDialog(dialog, (action) => {
      if (action === dialog.primaryBtnAction) {
        bind()
      }
    })
  }

  function backToStart() {
    const newParams = new URLSearchParams(window.location.search)
    newParams.delete('success')
    router.visit('/bind_start?' + newParams.toString())
  }

  function handleOverride() {
    const dialog = Dialog.BindingOverride({
      legacyName: toBindLegacyUser.name,
      legacyTotalMinutes: toBindLegacyUser.total_minutes,
      boundUsername: boundUser?.name ?? '-',
      boundUserTotalMinutes: boundUser?.total_minutes ?? -1,
    })
    showDialog(dialog, (action) => {
      if (action === dialog.primaryBtnAction) {
        bind()
      }
    })
  }
</script>

<div class="flex h-full flex-col overflow-y-auto px-9 pb-[50px]">
  <ProgressBar currentStep={2} />
  <div class="size-[66px] shrink-0">
    <BindIcon />
  </div>
  <h1
    class="mt-6 whitespace-pre-wrap text-[24px] font-bold text-content-primary text-headline-md"
  >
    {$_('legacy_account_connect_seekrid_to_forest')}
  </h1>
  <div class="mt-[26px] space-y-[6px]">
    <div class="flex rounded-2xl border-[1.5px] border-[#ECECEC] p-[10px]">
      <div class="size-10 shrink-0">
        <Seekrtech />
      </div>
      <div class="ml-[10px]">
        <h2 class="font-bold text-content-primary">{'SeekrID'}</h2>
        <p class="break-all text-sm font-medium text-content-secondary">
          {loginInfo}
        </p>
        {#if boundUser}
          <div
            class="mt-[6px] flex w-fit items-center rounded-[5px] bg-[#FFE0E0] p-[6px]"
          >
            <div class="size-4">
              <ErrorIcon />
            </div>
            <p class="ml-1 text-xs text-warning">
              {$_('legacy_account_connect_seekrid_info_existing_data')}
            </p>
          </div>
          <div class="mt-3 text-warning">
            <h1 class="font-bold">
              {$_('legacy_account_connect_seekr_forest_info_title')}
            </h1>
            <p class="whitespace-pre-line break-all text-sm">
              {$_('legacy_account_connect_seekr_forest_info_data', {
                values: {
                  name: boundUser.name,
                  total_minute: boundUser.total_minutes,
                },
              })}
            </p>
          </div>
        {/if}
      </div>
    </div>
    <div class="pl-[18px]">
      <Link />
    </div>
    <div class="flex rounded-2xl border-[1.5px] border-[#ECECEC] p-[10px]">
      <div class="size-10 shrink-0">
        <Forest />
      </div>
      <div class="ml-[10px]">
        <h2 class="font-bold text-content-primary">
          {$_('legacy_account_connect_seekr_forest_info_title')}
        </h2>
        <p class="whitespace-pre-line break-all text-sm text-content-secondary">
          {$_('legacy_account_connect_seekr_forest_info_data', {
            values: {
              name: toBindLegacyUser.name,
              total_minute: toBindLegacyUser.total_minutes,
            },
          })}
        </p>
      </div>
    </div>
  </div>

  <div class="min-h-20"></div>

  {#if boundUser}
    <div class="mt-auto flex flex-col items-center space-y-3">
      <button class="btn" disabled={isLoading} on:click={handleOverride}>
        {#if isLoading}
          <Spinner />
        {/if}
        <span>{$_('btn_continue_link')}</span>
      </button>
      <div class="relative w-[240px]">
        <p
          class="mx-auto w-max max-w-[146px] bg-white px-2 text-center text-xs text-[#666666]"
        >
          {$_('you_can_also')}
        </p>
        <div
          class="absolute left-0 right-0 top-1/2 z-[-1] h-[1.2px] -translate-y-1/2 bg-[#C9C9C9]"
        ></div>
      </div>
      <button class="btn bg-[#ECECEC] text-[#666666]" on:click={backToStart}>
        <span>{$_('btn_create_seekrid')}</span>
      </button>
      <p class="text-xs text-[#888888]">
        {$_('check_another_forest_data_footer')}
        <button class="underline" on:click={showIntercomMessenger}>
          {$_('contact_cs')}
        </button>
      </p>
    </div>
  {:else}
    <div class="mt-auto flex flex-col items-center space-y-4">
      <button class="btn" disabled={isLoading} on:click={confirmBinding}>
        {#if isLoading}
          <Spinner />
        {/if}
        <span>{$_('btn_link')}</span>
      </button>
      <button class="text-[#888888]" on:click={backToStart}>
        <span>{$_('btn_start_over')}</span>
      </button>
    </div>
  {/if}
</div>
