<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormContext } from '@/context/form'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { changeEmail } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { ChangeEmailForm } from '@/schema'
  import { _ } from 'svelte-i18n'

  export let user: {
    id: string
    email: string
  }

  const { validate, hasError, allTouched } = createFormContext({
    schema: ChangeEmailForm,
    t: $_,
  })

  let isLoading = false

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = validate(new FormData(event.currentTarget))
    isLoading = true
    const [result, payload] = await changeEmail({
      email: data.newEmail,
      password: data.password,
    })
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/email_validation', {
            user_id: user.id,
            from: '/change_email',
            email: data.newEmail,
          }).toString(),
          navBarTitle: $_('email_verification_title'),
          navBarVisible: true,
        })
        break
      case 'WRONG_PASSWORD':
        showDialog(Dialog.WrongPassword)
        break
      case 'EMAIL_USED':
        showDialog(Dialog.EmailUsed)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload.code))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} class="flex h-full flex-col">
  <Main>
    <TextField
      type="password"
      name="password"
      placeholder={$_('placeholder_change_email_password')}
      dataFieldName="password"
    />
    <TextField
      type="email"
      name="newEmail"
      placeholder={$_('placeholder_change_email_new_email')}
      dataFieldName="new_email"
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton email={user.email} />
    </div>
  </Main>
  <Footer>
    <button
      class="btn"
      disabled={isLoading || !$allTouched || $hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'continue_email_edit',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </Footer>
</form>
