<script lang="ts">
  import Landing from '@/layouts/landing.svelte'
  import { grantAccess } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { params, redirectUri } from '@/params'
  import type { User } from '@/types/record'
  import { router } from '@inertiajs/svelte'
  import { _ } from 'svelte-i18n'

  export let user: User

  let isLoading = false
  async function handleGrantAccess() {
    isLoading = true
    const [result, payload] = await grantAccess()
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        redirect(redirectUri, { ...Object.fromEntries(params), ...payload })
        dispatchWebViewAction({ action: 'end_session' })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
      default:
        showDialog(Dialog.UnhandledError(payload.message))
    }
  }

  async function backToSignIn() {
    const newParams = new URLSearchParams(params)
    newParams.set('skip_signed_in_check', 'true')
    router.visit('/entry?' + newParams.toString())
  }
</script>

<Landing
  title={$_('confirm_email_home_title')}
  subtitle={$_('confirm_email_home_content', {
    values: {
      logininfo:
        user.ssoProvider === 'apple'
          ? $_('apple')
          : user.ssoProvider === 'google'
            ? $_('google', { values: { email: user.email } })
            : user.email,
    },
  })}
>
  <div class="space-y-4">
    <button
      class="btn max-w-full"
      on:click={handleGrantAccess}
      disabled={isLoading}
    >
      {'OK'}
    </button>
    <button
      class="btn max-w-full bg-[#d9d9d9]"
      on:click={backToSignIn}
      disabled={isLoading}
    >
      {'Cancel'}
    </button>
  </div>
</Landing>
