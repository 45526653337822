<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormContext } from '@/context/form'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { peekUser } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { signInWithLegacy } from '@/lib/native-messaging/sign-in-with-legacy'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import {
    convertToken,
    disableSignup,
    params,
    toBindingFlowIfLegacy,
  } from '@/params'
  import { PeekEmailForm } from '@/schema'
  import { _ } from 'svelte-i18n'

  export let server: 'sg' | 'cn' | 'staging'
  export let legacySigninUrl: string

  const isGuest = !!convertToken

  const { validate, allTouched, hasError } = createFormContext({
    schema: PeekEmailForm,
    t: $_,
  })

  let isLoading = false
  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = validate(new FormData(event.currentTarget))

    isLoading = true
    const [result, resultData] = await peekUser(data.email)
    isLoading = false
    switch (result) {
      case 'LEGACY_USER_FOUND':
        if (isGuest) {
          showDialog(Dialog.GuestDataOverwritingWarning, (action) => {
            if (
              action === Dialog.GuestDataOverwritingWarning.primaryBtnAction
            ) {
              signInWithLegacy(data.email)
              dispatchWebViewAction({ action: 'end_session' })
            }
          })
        } else {
          if (toBindingFlowIfLegacy) {
            const url = new URL('/sign-in', legacySigninUrl)
            url.searchParams.set('email', data.email)
            url.searchParams.set('server', server)
            params.delete('disable_signup')
            url.searchParams.set(
              'auth',
              JSON.stringify(Object.fromEntries(params)),
            )
            location.href = url.toString()
          } else {
            signInWithLegacy(data.email)
            dispatchWebViewAction({ action: 'end_session' })
          }
        }
        break
      case 'USER_FOUND':
        if (isGuest) {
          showDialog(Dialog.GuestDataOverwritingWarning, (action) => {
            if (
              action === Dialog.GuestDataOverwritingWarning.primaryBtnAction
            ) {
              dispatchWebViewAction({
                action: 'push',
                url: resolveURL('/signin', { email: data.email }).toString(),
                navBarTitle: $_('signin_title'),
                navBarVisible: true,
              })
            }
          })
        } else {
          dispatchWebViewAction({
            action: 'push',
            url: resolveURL('/signin', { email: data.email }).toString(),
            navBarTitle: $_('signin_title'),
            navBarVisible: true,
          })
        }
        break
      case 'USER_NOT_FOUND':
        if (disableSignup) {
          showDialog(Dialog.SignUpDisabled, (action) => {
            if (action === Dialog.SignUpDisabled.primaryBtnAction) {
              location.href = 'https://forestapp.go.link/k5PoK'
            }
          })
        } else {
          dispatchWebViewAction({
            action: 'push',
            url: resolveURL('/signup', { email: data.email }).toString(),
            navBarTitle: $_('signup_title'),
            navBarVisible: true,
          })
        }
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(resultData))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(resultData.message))
        break
    }
  }
</script>

<form
  class="flex h-full flex-col"
  on:submit|preventDefault={handleSubmit}
  novalidate
>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('signup_or_signin_email_title')}
    </p>
    <TextField
      type="email"
      name="email"
      placeholder={'seekrtech@example.com'}
      dataFieldName="entry_email"
    />
  </Main>
  <Footer>
    <button
      class="btn"
      disabled={isLoading || !$allTouched || $hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'continue_entry',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </Footer>
</form>
