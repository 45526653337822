export function getGoogleOauthURL({
  clientId,
  state,
}: {
  clientId: string
  state: string
}) {
  const url = new URL('https://accounts.google.com/o/oauth2/v2/auth')
  const redirectUri = new URL(
    '/sso_callbacks/google_code',
    window.location.origin,
  )
  url.search = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri.href,
    response_type: 'code',
    scope:
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    state,
  }).toString()

  return url.href
}
