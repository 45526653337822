<script lang="ts">
  import BindStepSuccess from '@/components/icons/bind-step-success.svelte'
  import Bound from '@/components/icons/bound.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import { redirect } from '@/lib/oauth/redirect'
  import { redirectUri } from '@/params'
  import type { User } from '@/types/record'
  import { _ } from 'svelte-i18n'

  export let user: User

  const params = new URLSearchParams(window.location.search)

  const boundUsername = params.get('bound_user_name')
  const boundUserTotalMinutes = params.get('bound_user_total_minutes')

  function handleConfirmBtnClick() {
    if (redirectUri) {
      redirect(redirectUri, { ...Object.fromEntries(params) })
    } else {
      window.postMessage('success')
    }
  }
</script>

<div class="flex h-full flex-col px-9 pb-[50px]">
  <ProgressBar currentStep={3} shouldHideNavBtn />
  <BindStepSuccess />
  <div class="mt-6 flex-grow">
    <h1 class="text-[24px] font-bold text-content-primary text-headline-md">
      {$_('seekrid_set_success_title')}
    </h1>
    <p class="mt-[6px] text-content-tertiary">
      {$_('seekrid_set_success_content')}
    </p>
    <div
      class="mt-[26px] flex rounded-2xl border-[1.5px] border-[#ECECEC] p-[10px]"
    >
      <div class="h-10 w-[72px] shrink-0">
        <Bound />
      </div>
      <div class="ml-[10px]">
        <h2 class="font-bold text-content-primary">{'SeekrID'}</h2>
        <p class="break-all text-sm font-medium text-content-secondary">
          {user.email}
        </p>
        <div class="mt-2 text-sm">
          <h1 class="font-bold text-content-primary">
            {$_('legacy_account_connect_seekr_forest_info_title')}
          </h1>
          <p
            class="whitespace-pre-line break-all text-sm text-content-secondary"
          >
            {$_('legacy_account_connect_seekr_forest_info_data', {
              values: {
                name: boundUsername,
                total_minute: boundUserTotalMinutes,
              },
            })}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center">
    <button class="btn" on:click={handleConfirmBtnClick}>
      <span>{$_('btn_complete')}</span>
    </button>
  </div>
</div>
