import { waitForNextEventLoop } from '@/lib/utils/wait-for-next-event-loop'
import { IdTokenMessage, type ThirdPartySignOnMessage } from '@/types/message'

export function signOnWithThirdParty({
  provider,
}: {
  provider: ThirdPartySignOnMessage['payload']['provider']
}) {
  return new Promise<string>((resolve) => {
    window.postMessage({
      type: 'third_party_sign_on',
      payload: { provider },
    } satisfies ThirdPartySignOnMessage)

    function handleMessage(event: MessageEvent<unknown>) {
      const message = IdTokenMessage.parse(event.data)
      window.removeEventListener('message', handleMessage)
      resolve(message.payload.token)
    }

    waitForNextEventLoop().then(() => {
      window.addEventListener('message', handleMessage)
    })
  })
}
