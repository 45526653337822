<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import ResendSection from '@/components/resend-section.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormContext } from '@/context/form'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { grantAccess, resetPassword } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { resolveURL } from '@/lib/utils/url'
  import { redirectUri } from '@/params'
  import { ResetPasswordForm, ResetPasswordFormRefined } from '@/schema'
  import { _ } from 'svelte-i18n'

  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')
  const isBindingFlow = params.has('is_binding_flow')

  let isLoading = false

  const { validate, allTouched, hasError } = createFormContext({
    schema: ResetPasswordForm,
    refinedSchema: ResetPasswordFormRefined,
    t: $_,
  })

  async function submitGrantingAccess() {
    isLoading = true
    const [result, payload] = await grantAccess()
    isLoading = false
    if (result !== 'SUCCESS') {
      showDialog(Dialog.RetryDialog, (action) => {
        if (action === Dialog.RetryDialog.primaryBtnAction) {
          submitGrantingAccess()
        }
      })
      return
    }
    redirect(redirectUri, { ...Object.fromEntries(params), ...payload })
    dispatchWebViewAction({ action: 'dismiss' })
  }

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    if (!email) {
      return alert('`email` not set as query parameter')
    }
    const data = validate(new FormData(event.currentTarget))

    isLoading = true
    const [result, payload] = await resetPassword({
      email,
      code: data.code,
      newPassword: data.newPassword,
    })
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        if (isBindingFlow) {
          dispatchWebViewAction({
            action: 'push',
            navBarTitle: '',
            navBarVisible: true,
            url: resolveURL('/bind').toString(),
          })
        } else {
          showToast(Toast.ResetPasswordSuccess)
          sendAccountActionMessage({ action: 'reset_password' })
          submitGrantingAccess()
        }
        break
      case 'INVALID_CODE':
        showDialog(Dialog.WrongVerificationCode)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  {#if isBindingFlow}
    <ProgressBar currentStep={2} shouldSelfApplyPaddingX />
  {/if}
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('verification_code_sent', {
        values: {
          email: email,
        },
      })}
    </p>
    <TextField
      name="code"
      placeholder={$_('placeholder_verification_code')}
      dataFieldName="reset_verification"
    />
    <TextField
      type="password"
      name="newPassword"
      placeholder={$_('placeholder_new_password')}
      dataFieldName="new_password"
    />
    <TextField
      type="password"
      name="confirmNewPassword"
      placeholder={$_('placeholder_confirm_password')}
      dataFieldName="new_password_confirm"
    />
    <ResendSection tokenType="reset_password" hasSentEmailFromServer={false} />
  </Main>
  <Footer>
    <button class="btn" disabled={isLoading || !$allTouched || $hasError}>
      {#if isLoading}
        <Spinner />
      {/if}
      <span>
        {$_('btn_reset_password')}
      </span>
    </button>
  </Footer>
</form>
