import { t } from '@/lib/translate'
import { isStandalone } from '@/params'
import { toast } from 'svelte-sonner'
import { z } from 'zod'

export const ToastMessageSchema = z.object({
  type: z.literal('show_toast'),
  payload: z.object({
    type: z.union([
      z.literal('success'),
      z.literal('error'),
      z.literal('info'),
    ]),
    messageKey: z.string(),
  }),
})

export type ShowToastMessage = z.infer<typeof ToastMessageSchema>

export type Toast = z.infer<(typeof ToastMessageSchema)['shape']['payload']>

export function showToast(data: Toast) {
  if (isStandalone) {
    toast[data.type](t(data.messageKey))
  } else {
    const message: ShowToastMessage = {
      type: 'show_toast',
      payload: {
        ...data,
        messageKey: t(data.messageKey),
      },
    }
    window.postMessage(message)
  }
}

export const Toast = {
  ChangeEmailSuccess: {
    type: 'success',
    messageKey: 'toast_manage_account_change_email_success',
  },
  ChangePasswordSuccess: {
    type: 'success',
    messageKey: 'toast_manage_account_change_password_success',
  },
  ResetPasswordSuccess: {
    type: 'success',
    messageKey: 'toast_reset_password_success',
  },
  UsernameRequired: {
    type: 'error',
    messageKey: 'toast_user_name_empty',
  },
  UsernameTooLong: {
    type: 'error',
    messageKey: 'toast_text_max',
  },
  BindLegacyUserSuccess: {
    type: 'success',
    messageKey: 'toast_bind_legacy_user_success',
  },
} satisfies Record<string, Toast>
