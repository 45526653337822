<svg
  width="66"
  height="66"
  viewBox="0 0 66 66"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="66" height="66" rx="15" fill="url(#paint0_linear_5777_18867)" />
  <path
    d="M21 34.1818L28.4843 41.6661C28.8711 42.0529 29.4968 42.0572 29.8888 41.6758L46 26"
    stroke="#315F13"
    stroke-width="6"
    stroke-linecap="round"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5777_18867"
      x1="52.6936"
      y1="-4.48148"
      x2="21.1848"
      y2="33.4085"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FAFF66" />
      <stop offset="1" stop-color="#8DC925" />
    </linearGradient>
  </defs>
</svg>
