<script lang="ts">
  import AppleIcon from '@/components/icons/apple.svelte'
  import Email from '@/components/icons/email.svelte'
  import Google from '@/components/icons/google.svelte'
  import SignInButton from '@/components/ui/sign-in-button.svelte'
  import TextButton from '@/components/ui/text-button.svelte'
  import Landing from '@/layouts/landing.svelte'
  import { signInAsGuest, verifyGoogleIdToken } from '@/lib/api'
  import { clearURLErrorParams } from '@/lib/helpers'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    logComponentEvent,
    logGeneralEvent,
  } from '@/lib/native-messaging/log-event'
  import { signOnWithThirdParty } from '@/lib/native-messaging/third-party-sign-on'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { getGoogleOauthURL } from '@/lib/oauth/get-google-oauth-url'
  import { redirect } from '@/lib/oauth/redirect'
  import { scriptLoad } from '@/lib/utils/script-load'
  import { resolveURL } from '@/lib/utils/url'
  import {
    convertToken,
    isStandalone,
    params,
    platform,
    redirectUri,
  } from '@/params'
  import { _ } from 'svelte-i18n'

  export let googleOauthClientId: string

  const isChina = location.href.includes('upwardsware')

  // Handle redirect from SSO flow
  function handleSignInRedirect(params: URLSearchParams) {
    const error = params.get('error') as null | '401' | '403' | '410' | '405'
    if (error) {
      switch (error) {
        case '401':
          showDialog({
            type: 'default',
            name: 'id_token_validation_error_dialog',
            titleKey: 'ID Token Validation Error',
            contentKey: `Message: ${params.get('error_description')}`,
            primaryBtnKey: 'OK',
          })
          break
        case '403':
          showDialog(Dialog.WrongServer)
          break
        case '410':
          showDialog(Dialog.LegacyUserFound)
          break
        case '405':
          if (convertToken) {
            // Can't convert because user has already converted
            showDialog(Dialog.UnhandledHTTPError(405))
          } else {
            showDialog(Dialog.SignUpDisabled, (action) => {
              if (action === Dialog.SignUpDisabled.primaryBtnAction) {
                location.href = 'https://forestapp.go.link/k5PoK'
              }
            })
          }
          break
        default:
          showDialog(Dialog.UnhandledHTTPError(Number(error)))
          break
      }
      clearURLErrorParams()
    }
    if (params.has('code')) {
      logGeneralEvent('seekrauth_login', {
        is_guest: (!!convertToken).toString(),
      })
      redirect(redirectUri, { ...Object.fromEntries(params) })
      dispatchWebViewAction({ action: 'end_session' })
    }
  }
  handleSignInRedirect(params)

  function handleSignInWithEmailBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'email_signup',
    })
    dispatchWebViewAction({
      action: 'push',
      url: resolveURL('/signup_or_signin').toString(),
      navBarTitle: $_('signup_or_signin_title'),
      navBarVisible: true,
    })
  }

  function composeSsoState() {
    return JSON.stringify({
      ...Object.fromEntries(params),
      redirect_uri: new URL(location.href).toString(),
    })
  }

  let isSigningInWithGoogle = false
  async function handleSignInWithGoogleBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'google_signup',
    })

    isSigningInWithGoogle = true

    if (isStandalone) {
      const url = getGoogleOauthURL({
        clientId: googleOauthClientId,
        state: composeSsoState(),
      })
      window.location.href = url
      return
    }

    // TODO: Uncomment this on Google Play release
    // if (compare(version ?? '0.0.0', '4.82.0', '<')) {
    //   showDialog(Dialog.GoogleSignInUnavailable, (action) => {
    //     if (action === Dialog.GoogleSignInUnavailable.primaryBtnAction) {
    //       window.location.href = 'https://forestapp.go.link/3FKjt'
    //     }
    //   })
    //   return
    // }

    const idToken = await signOnWithThirdParty({ provider: 'google' })
    isSigningInWithGoogle = false

    if (idToken === 'null') {
      showDialog(Dialog.RetryDialog)
      return
    }

    isSigningInWithGoogle = true
    const [result, payload] = await verifyGoogleIdToken({
      idToken,
      state: composeSsoState(),
    })
    isSigningInWithGoogle = false

    switch (result) {
      case 'REDIRECT':
        handleSignInRedirect(new URL(payload).searchParams)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }

  let isSigningInWithApple = false
  const appleIdScript = scriptLoad()
  const loadingAppleScript = appleIdScript.loading.then(() => {
    window.AppleID.auth.init({
      clientId: 'com.seekrtech.auth',
      scope: 'name email',
      redirectURI: new URL('/sso_callbacks/apple', location.href).toString(),
      state: composeSsoState(),
    })
  })
  async function handleSignInWithAppleBtnClick() {
    isSigningInWithApple = true
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'apple_signup',
    })
    await loadingAppleScript
    await window.AppleID.auth.signIn()
  }

  let isLoading = false
  async function handleSignInAsGuestBtnClick() {
    isLoading = true
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'guest_signup',
    })
    const [result, data] = await signInAsGuest()
    isLoading = false
    switch (result) {
      case 'SUCCESS':
        redirect(redirectUri, { ...Object.fromEntries(params), ...data })
        dispatchWebViewAction({ action: 'end_session' })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(data))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(data.message))
        break
    }
  }
</script>

<svelte:head>
  <!-- https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3235722 -->
  <script
    type="text/javascript"
    src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    on:load={appleIdScript.onLoad}
    on:error={appleIdScript.onError}
  ></script>
</svelte:head>

<Landing title={$_('login_home_title')} subtitle={$_('login_home_subtitle')}>
  <div class="flex flex-col gap-4">
    <SignInButton
      on:click={handleSignInWithAppleBtnClick}
      disabled={isSigningInWithApple}
    >
      <AppleIcon slot="icon" />
      <span slot="text">{$_('login_btn_thirdparty_apple')}</span>
    </SignInButton>
    {#if !isChina && (platform === 'android' || isStandalone)}
      <SignInButton
        on:click={handleSignInWithGoogleBtnClick}
        disabled={isSigningInWithGoogle}
      >
        <Google slot="icon" />
        <span slot="text">{$_('login_btn_thirdparty_google')}</span>
      </SignInButton>
    {/if}
    <SignInButton on:click={handleSignInWithEmailBtnClick}>
      <Email slot="icon" />
      <span slot="text">{$_('login_btn_seekrauth')}</span>
    </SignInButton>
    <p class="text-[16px] text-content-secondary">{$_('login_reminder')}</p>
    {#if convertToken === null && !isStandalone}
      <TextButton
        class="self-start text-secondary"
        on:click={handleSignInAsGuestBtnClick}
        disabled={isLoading}
      >
        {$_('login_guest')}
      </TextButton>
    {/if}
  </div>
</Landing>
