<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import BindStepEmail from '@/components/icons/bind-step-email.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormContext } from '@/context/form'
  import { peekUser } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { PeekEmailForm } from '@/schema'
  import { _ } from 'svelte-i18n'

  let isLoading = false

  const { validate, allTouched, hasError } = createFormContext({
    schema: PeekEmailForm,
    t: $_,
  })

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = validate(new FormData(event.currentTarget))

    isLoading = true
    const [result, resultData] = await peekUser(data.email)
    isLoading = false
    switch (result) {
      case 'USER_FOUND':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/bind_sign_in', { email: data.email }).toString(),
          navBarTitle: '',
          navBarVisible: true,
        })
        break
      case 'USER_NOT_FOUND':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/signup', { email: data.email }).toString(),
          navBarTitle: $_('signup_title'),
          navBarVisible: true,
        })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog({
          type: 'default',
          name: 'unhandled_http_error',
          titleKey: 'Unhandled HTTP Error',
          contentKey: `Code: ${resultData}`,
          primaryBtnKey: 'OK',
        })
        break
      case 'UNHANDLED_ERROR':
        showDialog({
          type: 'default',
          name: 'unhandled_error',
          titleKey: 'Unhandled Error',
          contentKey: `Messsage: ${resultData.message}`,
          primaryBtnKey: 'OK',
        })
        break
    }
  }
</script>

<form
  class="flex h-full flex-col px-9 pb-[50px]"
  on:submit|preventDefault={handleSubmit}
>
  <ProgressBar currentStep={1} />
  <BindStepEmail />
  <div class="mt-6 flex-grow space-y-4">
    <h1 class="text-[24px] font-bold text-content-primary text-headline-md">
      {$_('legacy_account_create_seekrid_title')}
    </h1>
    <TextField
      type="email"
      name="email"
      placeholder={'seekrtech@example.com'}
      dataFieldName="bind_email"
    />
    <p class="text-right text-sm text-content-tertiary">
      {$_('create_email_reminder')}
    </p>
  </div>
  <div class="flex justify-center">
    <button
      class="btn"
      type="submit"
      disabled={isLoading || !$allTouched || $hasError}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('continue_btn')}</span>
    </button>
  </div>
</form>
