<script lang="ts">
  import { sendVerificationEmail } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { resolveURL } from '@/lib/utils/url'
  import { language } from '@/params'
  import { onMount } from 'svelte'
  import { _ } from 'svelte-i18n'

  export let tokenType: 'signup' | 'change_email' | 'reset_password'
  export let hasSentEmailFromServer: boolean

  let isLoading = false

  let endpoint: string
  switch (tokenType) {
    case 'signup':
      endpoint = '/resend_signup_email'
      break
    case 'change_email':
      endpoint = '/resend_change_email'
      break
    case 'reset_password':
      endpoint = '/reset_token'
      break
  }

  const DEFAULT_COUNT_DOWN = 30

  let countDown = hasSentEmailFromServer ? DEFAULT_COUNT_DOWN : 0

  onMount(() => {
    if (hasSentEmailFromServer === false) {
      sendEmail()
    }
  })

  async function sendEmail() {
    isLoading = true
    const [result] = await sendVerificationEmail(
      resolveURL(endpoint).toString(),
      language.replace('_', '-'),
    )
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        countDown = DEFAULT_COUNT_DOWN
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.RetryDialog, async (action) => {
          if (action === Dialog.RetryDialog.primaryBtnAction) {
            sendEmail()
          }
        })
        break
    }
  }

  let interval: number
  $: {
    if (countDown === DEFAULT_COUNT_DOWN) {
      interval = window.setInterval(() => {
        if (countDown === 0) {
          clearInterval(interval)
          return
        }
        countDown -= 1
      }, 1000)
    }
  }

  function handleResendClick() {
    logComponentEvent({
      type: 'text',
      appAction: 'click',
      componentName: 'resend_confirmation',
    })
    sendEmail()
  }
</script>

<p class="text-right text-content-tertiary text-body-md">
  {#if countDown > 0}
    {$_('verification_code_resend_countdown', {
      values: {
        second: `${countDown}`,
      },
    })}
  {:else}
    {$_('verification_code_resend').replace('{resend}', '')}
    <button
      id="resend"
      type="button"
      class="text-secondary underline disabled:opacity-50"
      on:click={handleResendClick}
      disabled={isLoading}
    >
      {$_('resend')}
    </button>
  {/if}
</p>
