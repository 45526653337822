import { redirect } from '@/lib/oauth/redirect'
import { isStandalone, redirectUri } from '@/params'
import type { Message } from '@/types/message'

export function signInWithLegacy(email: string) {
  if (isStandalone) {
    redirect(redirectUri, { sign_in_with_legacy: email })
  } else {
    window.postMessage({
      type: 'sign_in_with_legacy',
      payload: { email },
    } satisfies Message)
  }
}
