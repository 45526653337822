<svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="40" height="40" rx="8" fill="#28A9FF" />
  <path
    d="M24.9223 10.0002C24.3245 10.0002 23.8415 10.4832 23.8415 11.0809V12.7866H16.1645V11.0809C16.1645 10.4832 15.6815 10.0002 15.0838 10.0002C14.486 10.0002 14 10.4832 14 11.0809V16.9134V28.8319C14 29.4296 14.483 29.9126 15.0808 29.9126C15.6785 29.9126 16.1615 29.4296 16.1615 28.8319V27.1262H23.8385V28.8319C23.8385 29.4296 24.3215 29.9126 24.9192 29.9126C25.517 29.9126 26 29.4296 26 28.8319V23.0024V11.0809C26 10.4832 25.517 10.0002 24.9192 10.0002H24.9223ZM21.8823 24.9647H16.1645V20.5451C16.7472 20.8621 17.4174 21.0402 18.1268 21.0402H21.8823C22.966 21.0402 23.8445 21.9187 23.8445 23.0024C23.8445 24.0862 22.966 24.9647 21.8823 24.9647ZM23.8415 19.3708C23.2589 19.0538 22.5887 18.8757 21.8792 18.8757H18.1238C17.04 18.8757 16.1615 17.9972 16.1615 16.9134C16.1615 15.8296 17.04 14.9511 18.1238 14.9511H23.8415V19.3708Z"
    fill="white"
  />
</svg>
