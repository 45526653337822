<svg
  width="66"
  height="66"
  viewBox="0 0 66 66"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="66" height="66" rx="15" fill="url(#paint0_linear_5710_13495)" />
  <path
    d="M33 20V46"
    stroke="#315F13"
    stroke-width="5.5"
    stroke-linecap="round"
  />
  <path
    d="M46 33L20 33"
    stroke="#315F13"
    stroke-width="5.5"
    stroke-linecap="round"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5710_13495"
      x1="52.6936"
      y1="-4.48148"
      x2="21.1848"
      y2="33.4085"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FAFF66" />
      <stop offset="1" stop-color="#8DC925" />
    </linearGradient>
  </defs>
</svg>
