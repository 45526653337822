<script lang="ts">
  import { dispatchDialogAction } from '@/lib/native-messaging/dialog'
  import { flyAndScale } from '@/lib/utils/transitions'
  import { dialog } from '@/stores'
  import { Dialog } from 'bits-ui'
  import clsx from 'clsx'
  import { fade } from 'svelte/transition'

  function handlePrimaryBtnClick() {
    if ($dialog?.primaryBtnAction) {
      dispatchDialogAction($dialog.primaryBtnAction)
    }
    dismiss()
  }

  function handleSecondaryBtnClick() {
    if ($dialog?.secondaryBtnAction) {
      dispatchDialogAction($dialog.secondaryBtnAction)
    }
    dismiss()
  }

  function dismiss() {
    $dialog = null
  }
</script>

<Dialog.Root
  open={!!$dialog}
  onOpenChange={(open) => {
    if (!open) {
      $dialog = null
    }
  }}
  closeOnOutsideClick={false}
  closeOnEscape={false}
>
  <Dialog.Portal>
    <Dialog.Overlay
      class="fixed inset-0 z-50 bg-black/40"
      transition={fade}
      transitionConfig={{ duration: 150 }}
    />
    <Dialog.Content
      class="fixed left-[50%] top-[50%] z-50 w-full max-w-[300px] translate-x-[-50%] translate-y-[-50%] rounded-[1.25rem] bg-white p-5 text-center shadow-md"
      transition={flyAndScale}
    >
      <div class="space-y-2">
        <Dialog.Title class="text-content-primary text-subtitle-md"
          >{$dialog?.titleKey}</Dialog.Title
        >
        <Dialog.Description class="text-content-secondary text-body-md"
          >{$dialog?.contentKey}</Dialog.Description
        >
      </div>
      <div class={clsx('mt-6', $dialog?.secondaryBtnKey && 'flex gap-2')}>
        {#if $dialog?.secondaryBtnKey}
          <button
            class={clsx(
              'line-height-[22px] btn h-10 w-full min-w-[129px] max-w-[129px] bg-[#d9d9d9]',
            )}
            on:click={handleSecondaryBtnClick}
          >
            {$dialog?.secondaryBtnKey}
          </button>
        {/if}
        <button
          class={clsx(
            'line-height-[22px] btn h-10 w-full min-w-[129px] max-w-[129px]',
            $dialog?.type === 'warning' && 'bg-warning',
          )}
          on:click={handlePrimaryBtnClick}
        >
          {$dialog?.primaryBtnKey ?? 'OK'}
        </button>
      </div>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
