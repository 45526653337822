<script lang="ts">
  import landing from '@/assets/images/landing.png'
  import ServerSelect from '@/components/server-select.svelte'

  export let title = ''
  export let subtitle = ''
</script>

<div class="flex h-full flex-col">
  <div class="relative max-h-[396px] min-h-0 flex-1">
    <img src={landing} alt="landing" class="h-full object-cover" />
  </div>
  <div class="mt-auto flex flex-col gap-[30px] px-9 pb-[50px]">
    <div class="text-content-primary">
      {#if title}
        <h1 class="font-bold text-headline-md">
          {title}
        </h1>
      {/if}
      {#if subtitle}
        <p class="mt-[5px] text-[14px]">
          {subtitle}
        </p>
      {/if}
      <ServerSelect />
    </div>
    <slot />
  </div>
</div>
