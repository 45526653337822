<script lang="ts">
  import AppleIcon from '@/components/icons/apple.svelte'
  import BindStep_1 from '@/components/icons/bind-step-1.svelte'
  import Email from '@/components/icons/email.svelte'
  import Google from '@/components/icons/google.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import ServerSelect from '@/components/server-select.svelte'
  import SignInButton from '@/components/ui/sign-in-button.svelte'
  import { verifyGoogleIdToken } from '@/lib/api'
  import { clearURLErrorParams } from '@/lib/helpers'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { signOnWithThirdParty } from '@/lib/native-messaging/third-party-sign-on'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { getGoogleOauthURL } from '@/lib/oauth/get-google-oauth-url'
  import { scriptLoad } from '@/lib/utils/script-load'
  import { resolveURL } from '@/lib/utils/url'
  import { isStandalone, platform } from '@/params'
  import { _ } from 'svelte-i18n'

  export let googleOauthClientId: string

  const isChina = location.href.includes('upwardsware')
  const params = new URLSearchParams(window.location.search)

  // Handle redirect from SSO flow
  function handleSignInRedirect(params: URLSearchParams) {
    const error = params.get('error') as null | '401' | '403' | '410'
    if (error) {
      switch (error) {
        case '401':
          showDialog({
            type: 'default',
            name: 'id_token_validation_error_dialog',
            titleKey: 'ID Token Validation Error',
            contentKey: `Message: ${params.get('error_description')}`,
            primaryBtnKey: 'OK',
          })
          break
        case '403':
          showDialog(Dialog.WrongServer)
          break
        case '410':
          showDialog(Dialog.LegacyUserFound)
          break
        default:
          showDialog(Dialog.UnhandledHTTPError(Number(error)))
          break
      }
      clearURLErrorParams()
    }
    if (params.has('success')) {
      dispatchWebViewAction({
        action: 'push',
        navBarTitle: '',
        navBarVisible: true,
        url: resolveURL('/bind').toString(),
      })
    }
  }
  handleSignInRedirect(params)

  function handleSignInWithEmailBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'email_signup',
    })
    dispatchWebViewAction({
      action: 'push',
      url: resolveURL('/bind_peek', {
        is_binding_flow: 'true',
      }).toString(),
      navBarTitle: '',
      navBarVisible: true,
    })
  }

  function composeSsoState() {
    return JSON.stringify({
      ...Object.fromEntries(params),
      redirect_uri: location.href,
      is_binding_flow: 'true',
    })
  }

  let isSigningInWithGoogle = false
  async function handleSignInWithGoogleBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'google_signup',
    })

    isSigningInWithGoogle = true

    if (platform === 'web') {
      const url = getGoogleOauthURL({
        clientId: googleOauthClientId,
        state: composeSsoState(),
      })
      window.location.href = url
      return
    }

    const idToken = await signOnWithThirdParty({ provider: 'google' })
    isSigningInWithGoogle = false

    if (idToken === 'null') {
      showDialog(Dialog.RetryDialog)
      return
    }

    isSigningInWithGoogle = true
    const [result, payload] = await verifyGoogleIdToken({
      idToken,
      state: composeSsoState(),
    })
    isSigningInWithGoogle = false

    switch (result) {
      case 'REDIRECT':
        handleSignInRedirect(new URL(payload).searchParams)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }

  let isSigningInWithApple = false
  const appleIdScript = scriptLoad()
  const loadingAppleScript = appleIdScript.loading.then(() => {
    window.AppleID.auth.init({
      clientId: 'com.seekrtech.auth',
      scope: 'name email',
      redirectURI: new URL('/sso_callbacks/apple', location.href).toString(),
      state: composeSsoState(),
    })
  })
  async function handleSignInWithAppleBtnClick() {
    isSigningInWithApple = true
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'apple_signup',
    })
    await loadingAppleScript
    await window.AppleID.auth.signIn().catch(console.error)
  }
</script>

<svelte:head>
  <!-- https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3235722 -->
  <script
    type="text/javascript"
    src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    on:load={appleIdScript.onLoad}
    on:error={appleIdScript.onError}
  ></script>
</svelte:head>

<div class="flex h-full flex-col px-9">
  <ProgressBar currentStep={1} />
  <BindStep_1 />
  <div class="mt-6 space-y-[6px] text-content-primary">
    <h1 class="text-[24px] font-bold text-headline-md">
      {$_('legacy_account_create_seekrid_title')}
    </h1>
    <ServerSelect readOnly />
  </div>
  <div class="mt-[26px] flex flex-col space-y-4">
    {#if isStandalone || platform === 'ios'}
      <SignInButton
        on:click={handleSignInWithAppleBtnClick}
        disabled={isSigningInWithApple}
      >
        <AppleIcon slot="icon" />
        <span slot="text">{$_('login_btn_thirdparty_apple')}</span>
      </SignInButton>
    {/if}
    {#if !isChina && platform !== 'ios'}
      <SignInButton
        on:click={handleSignInWithGoogleBtnClick}
        disabled={isSigningInWithGoogle}
      >
        <Google slot="icon" />
        <span slot="text">{$_('login_btn_thirdparty_google')}</span>
      </SignInButton>
    {/if}
    <SignInButton on:click={handleSignInWithEmailBtnClick}>
      <Email slot="icon" />
      <span slot="text">{$_('login_btn_seekrauth')}</span>
    </SignInButton>
  </div>
</div>
