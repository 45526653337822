<svg
  width="66"
  height="66"
  viewBox="0 0 66 66"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="66" height="66" rx="15" fill="url(#paint0_linear_5710_23061)" />
  <path
    d="M19.4 45.2821C18.465 45.2821 17.6646 44.9726 16.9988 44.3537C16.3329 43.7348 16 42.9909 16 42.1218V23.1603C16 22.2912 16.3329 21.5472 16.9988 20.9283C17.6646 20.3094 18.465 20 19.4 20H46.6C47.535 20 48.3354 20.3094 49.0013 20.9283C49.6671 21.5472 50 22.2912 50 23.1603V42.1218C50 42.9909 49.6671 43.7348 49.0013 44.3537C48.3354 44.9726 47.535 45.2821 46.6 45.2821H19.4ZM33 34.2212L46.6 26.3205V23.1603L33 31.0609L19.4 23.1603V26.3205L33 34.2212Z"
    fill="#315F13"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5710_23061"
      x1="52.6936"
      y1="-4.48148"
      x2="21.1848"
      y2="33.4085"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FAFF66" />
      <stop offset="1" stop-color="#8DC925" />
    </linearGradient>
  </defs>
</svg>
