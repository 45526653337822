<script lang="ts">
  import Cell from '@/components/cell.svelte'
  import Username from '@/components/username.svelte'
  import { logOut } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    dismissLoadingView,
    showLoadingView,
  } from '@/lib/native-messaging/loading-view'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { audience } from '@/params'
  import type { User } from '@/types/record'

  export let user: User

  async function signOut() {
    showLoadingView()
    const [result, payload] = await logOut()
    dismissLoadingView()

    switch (result) {
      case 'SUCCESS':
        sendAccountActionMessage({ action: 'sign_out' })
        dispatchWebViewAction({ action: 'end_session' })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload.code))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }

  function handleLogoutCellClick() {
    switch (audience) {
      case 'seekrtech.forest':
      case 'upwardsware.forest':
        showDialog(Dialog.ForestSignOut, (action) => {
          if (action === Dialog.ForestSignOut.primaryBtnAction) {
            signOut()
          }
        })
        break
      default:
        showDialog(Dialog.SignOut, (action) => {
          if (action === Dialog.SignOut.primaryBtnAction) {
            signOut()
          }
        })
        break
    }
  }

  document.documentElement.style.backgroundColor = 'var(--bg-secondary)'
</script>

<div class="h-full space-y-4 bg-bg-secondary px-5 pt-3">
  <div class="space-y-2">
    <Username username={user.name} userId={user.id} />
    <div class="text-content-secondary text-body-md">{user.email}</div>
  </div>
  <div class="flex flex-col overflow-hidden rounded-[16px] bg-white">
    <Cell
      componentName="edit_email"
      titleKey="manage_account_change_email"
      pathname="/change_email"
      disabled={user.ssoProvider !== 'seekrauth'}
    />
    <Cell
      componentName="edit_password"
      titleKey="manage_account_change_password"
      pathname="/change_password"
      disabled={user.ssoProvider !== 'seekrauth'}
    />
    <Cell
      componentName="logout"
      titleKey="manage_account_log_out"
      action={handleLogoutCellClick}
    />
    <Cell
      componentName="delete_account"
      titleKey="manage_account_delete_account"
      pathname="/delete_account"
      isDanger
    />
  </div>
</div>
