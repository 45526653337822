<svg viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3105_140960)">
    <path
      d="M794 0.0200195H230C102.975 0.0200195 0 102.995 0 230.02V793.97C0 920.995 102.975 1023.97 230 1023.97H794C921.026 1023.97 1024 920.995 1024 793.97V230.02C1024 102.995 921.026 0.0200195 794 0.0200195Z"
      fill="url(#paint0_linear_3105_140960)"
    />
    <mask
      id="mask0_3105_140960"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="1024"
      height="1024"
    >
      <path
        d="M794 0.0200195H230C102.975 0.0200195 0 102.995 0 230.02V793.97C0 920.995 102.975 1023.97 230 1023.97H794C921.026 1023.97 1024 920.995 1024 793.97V230.02C1024 102.995 921.026 0.0200195 794 0.0200195Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3105_140960)">
      <path d="M1024 766.48H0V1024H1024V766.48Z" fill="#724406" />
      <path
        d="M794 678.5H230C103 678.5 0 714 0 757.68V775.3C0 819 103 854.48 230 854.48H794C921 854.48 1024 819.03 1024 775.3V757.68C1024 714 921 678.5 794 678.5Z"
        fill="#845318"
      />
    </g>
    <path
      d="M533.93 697.54C533.93 697.54 531.33 606.3 554.3 604.22C577.27 602.14 593.68 630.14 656.83 624.4C740.71 616.77 797.03 536.51 811.32 459.47C811.32 459.47 779.19 485.37 741.91 490.78C698.76 497.04 656.18 478.53 601.76 497.34C561.84 511.14 540.43 561.44 537.6 598.65C537.6 598.65 519.54 634.84 519.6 693.02L533.93 697.54Z"
      fill="#8CC914"
    />
    <path
      d="M537.6 598.65C537.6 598.65 519.54 634.84 519.6 693.02H525.66C525.66 693.02 528.66 631.5 537.66 598.65"
      fill="#73A611"
    />
    <path
      d="M510.84 759.67C510.84 759.67 517.69 714.25 517.69 686.07C517.69 657.89 519.9 590.19 477.53 594.44C453.53 596.84 376.87 600 328.19 561.65C270.41 516.15 259.51 427.45 192.68 402.96C192.68 402.96 327.57 371.68 428.25 432.29C490.65 469.86 501.11 515.07 501.25 581.08C501.25 581.08 520.8 598.47 527.87 631.71C534.43 662.51 539.69 707.19 539.29 759.71L510.84 759.67Z"
      fill="#8CC914"
    />
    <path
      d="M477.53 594.44C477.53 594.44 511.25 586 519.53 637.5C524.53 668.6 523.72 710.75 519.53 759.67H510.81C510.81 759.67 517.66 714.25 517.66 686.07C517.66 657.89 519.87 590.19 477.5 594.44"
      fill="#73A611"
    />
    <path
      d="M493.31 581.55C492.43 579.81 491.55 578.1 490.6 576.42C489.65 574.74 488.73 573.05 487.75 571.42C485.75 568.12 483.727 564.873 481.68 561.68C477.48 555.313 473.033 549.157 468.34 543.21C463.647 537.263 458.7 531.557 453.5 526.09C448.339 520.614 442.905 515.4 437.22 510.47C431.57 505.47 425.7 500.78 419.58 496.38C416.58 494.09 413.4 492.06 410.31 489.89C408.75 488.82 407.12 487.89 405.54 486.82C403.96 485.75 402.38 484.74 400.73 483.82C387.56 475.962 373.962 468.845 360 462.5C375.429 465.189 390.403 470.036 404.48 476.9C406.28 477.71 408 478.65 409.75 479.56C411.5 480.47 413.23 481.39 414.93 482.38C418.33 484.38 421.73 486.38 424.99 488.59C428.25 490.8 431.46 493.1 434.65 495.43C436.23 496.62 437.73 497.9 439.28 499.13L441.57 501L443.8 503C446.8 505.59 449.63 508.34 452.47 511.09C455.31 513.84 457.96 516.77 460.47 519.78C465.644 525.739 470.393 532.054 474.68 538.68C478.965 545.263 482.749 552.159 486 559.31C487.56 562.91 489 566.54 490.28 570.25C491.555 573.94 492.568 577.717 493.31 581.55Z"
      fill="#73A611"
    />
    <path
      d="M546 598.64C546.716 595.711 547.721 592.861 549 590.13C550.259 587.373 551.695 584.701 553.3 582.13C556.533 576.945 560.307 572.118 564.56 567.73C568.837 563.323 573.525 559.335 578.56 555.82C581.09 554.08 583.68 552.42 586.34 550.9L588.34 549.75L590.34 548.75C591.72 548.07 593.06 547.31 594.46 546.7C600.012 544.135 605.752 541.999 611.63 540.31L616.06 539.18C616.8 539 617.53 538.78 618.27 538.63L620.51 538.2L624.98 537.33L629.48 536.72C641.419 535.141 653.528 535.367 665.4 537.39C653.62 539.49 642.12 541.52 630.95 544.39L626.77 545.48L622.64 546.7C619.88 547.46 617.2 548.45 614.48 549.31L610.48 550.78L608.48 551.51L606.48 552.33C605.17 552.88 603.84 553.33 602.54 553.94L598.67 555.71C588.37 560.462 578.68 566.439 569.81 573.51C565.336 577.097 561.1 580.972 557.13 585.11C553.061 589.312 549.339 593.837 546 598.64Z"
      fill="#73A611"
    />
  </g>
  <defs>
    <linearGradient
      id="paint0_linear_3105_140960"
      x1="512"
      y1="0.0200195"
      x2="512"
      y2="711.01"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#D4FF99" />
      <stop offset="0.27" stop-color="#DFFF99" />
      <stop offset="1" stop-color="#F9FF99" />
    </linearGradient>
    <clipPath id="clip0_3105_140960">
      <rect width="1024" height="1024" fill="white" />
    </clipPath>
  </defs>
</svg>
