export function resolveURL(
  pathname = '/',
  searchParams: Record<string, string | number | boolean> = {},
) {
  const url = new URL(location.href)
  url.pathname = pathname
  Object.entries(searchParams).forEach(([key, val]) => {
    url.searchParams.set(key, val.toString())
  })
  return url
}
