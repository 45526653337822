const loading = import('@intercom/messenger-js-sdk').then((module) => {
  module.default({
    app_id: 'y1kzl408',
    hide_default_launcher: true,
  })
  return module.show
})

export async function showIntercomMessenger() {
  ;(await loading)()
}
