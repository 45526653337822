<script lang="ts">
  import { platform } from '@/params'
  import Close from './icons/close.svelte'

  export let title: string

  function handleClose() {
    window.postMessage('close')
  }
</script>

<div class="relative mb-2 mt-[env(safe-area-inset-top)] h-[58px] flex-shrink-0">
  {#if platform !== 'web'}
    <button
      type="button"
      class="absolute left-5 top-[10px] size-6"
      on:click={handleClose}
    >
      <Close />
    </button>
  {/if}
  <h1 class="mt-[10px] text-center text-content-primary text-headline-md">
    {title}
  </h1>
</div>
