<svg
  width="66"
  height="66"
  viewBox="0 0 66 66"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="66" height="66" rx="15" fill="url(#paint0_linear_5710_20877)" />
  <path
    d="M32.6713 18.1145C28.3307 13.9653 22.757 13.5856 18.1141 18.4428C13.4712 23.2999 14.1018 28.8509 18.4424 33L22.9081 37.2687C27.2487 41.4178 32.8224 41.7975 37.4653 36.9403C37.6019 36.7974 38.7117 35.6364 38.8882 35.4518C40.7849 33.4676 37.8078 30.6218 35.9111 32.606C35.7232 32.8025 34.3985 34.1883 34.3985 34.1883C31.4871 37.1596 28.527 36.9424 25.7538 34.2916L21.2882 30.0229C18.4837 27.3422 18.1458 24.3699 21.0912 21.2886C24.0366 18.2072 27.0211 18.4108 29.8255 21.0916L31.3141 22.5145C32.1357 23.2999 33.4401 23.2705 34.2255 22.4488C35.0109 21.6271 34.9815 20.3228 34.1598 19.5373L32.6713 18.1145ZM43.0912 28.0747C40.9759 26.0528 38.6817 25.0049 36.3286 24.9036C33.4435 24.7807 30.738 26.0972 28.5339 28.403C28.3504 28.595 28.1128 28.8436 27.1111 29.8916C26.3256 30.7132 26.355 32.0176 27.1767 32.803C27.9984 33.5884 29.3027 33.559 30.0882 32.7373C31.0899 31.6894 31.3275 31.4408 31.5111 31.2488C32.9581 29.7349 34.5772 28.9381 36.1488 29.0047C37.4492 29.0598 38.8208 29.6901 40.2454 31.0518L44.7111 35.3205C47.7924 38.2659 47.5888 41.2504 44.9081 44.0548C42.2273 46.8592 39.255 47.1972 36.1737 44.2518L34.6852 42.8289C33.8635 42.0435 32.5592 42.0729 31.7737 42.8946C30.9883 43.7163 31.0177 45.0206 31.8394 45.806L33.3279 47.2289C38.1851 51.8718 43.736 51.2412 47.8852 46.9006C52.0343 42.56 52.414 36.9862 47.5568 32.3434L43.0912 28.0747Z"
    fill="#315F13"
  />
  <defs>
    <linearGradient
      id="paint0_linear_5710_20877"
      x1="52.6936"
      y1="-4.48148"
      x2="21.1848"
      y2="33.4085"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FAFF66" />
      <stop offset="1" stop-color="#8DC925" />
    </linearGradient>
  </defs>
</svg>
