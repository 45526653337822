export function redirect(
  redirectUri: string,
  payload: { [key: string]: string | number | null },
) {
  const url = new URL(redirectUri)
  const params = {
    ...payload,
    // let extension know if the origin has changed (sg/cn)
    origin: window.location.origin,
  }
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value?.toString() ?? '')
  })
  location.href = url.toString()
}
