<script lang="ts">
  import { createFormContext } from '@/context/form'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { showToast } from '@/lib/native-messaging/toast'
  import { flyAndScale } from '@/lib/utils/transitions'
  import { AgeScreeningForm } from '@/schema'
  import { Dialog } from 'bits-ui'
  import copy from 'copy-to-clipboard'
  import { _ } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import Spinner from './anim/spinner.svelte'
  import TextField from './text-field.svelte'

  export let isOpen: boolean
  export let onSubmit: (code: string) => void
  export let onClose: () => void
  export let isLoading: boolean
  export let error: string

  const formKey = 'age-screening-form'

  const { validate, form } = createFormContext({
    key: formKey,
    schema: AgeScreeningForm,
    t: $_,
  })

  form.subscribe((form) => {
    if (!form['code']?.error) {
      error = ''
    }
  })

  $: {
    if (error) {
      $form['code']!.error = error
    }
  }

  function handleSubmit(
    event: SubmitEvent & { currentTarget: HTMLFormElement },
  ) {
    const data = validate(new FormData(event.currentTarget))
    onSubmit(data.code)
  }

  async function handleContactUsBtnClick() {
    // Use this library instead of navigator.clipboard.writeText to support iOS 13
    copy('forest.age@seekrtech.com')
    showToast({
      type: 'info',
      messageKey: 'age_copy_email_copied',
    })
  }
</script>

<Dialog.Root open={isOpen} onOpenChange={(open) => !open && onClose()}>
  <Dialog.Portal>
    <Dialog.Overlay
      class="fixed inset-0 z-50 bg-black/40"
      transition={fade}
      transitionConfig={{ duration: 150 }}
    />
    <Dialog.Content
      class="fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] rounded-[1.25rem] bg-white p-5 text-center shadow-md"
      transition={flyAndScale}
    >
      <form on:submit|preventDefault={handleSubmit}>
        <div class="space-y-2">
          <Dialog.Title class="text-content-primary text-subtitle-md"
            >{$_('dialog_age_invalid_title')}</Dialog.Title
          >
          <Dialog.Description class="text-content-secondary text-body-md"
            >{$_('dialog_age_invalid_context')}</Dialog.Description
          >
          <TextField
            name="code"
            placeholder={$_('dialog_age_invalid_title')}
            dataFieldName="age_screen_code"
            of={formKey}
          />
        </div>
        <div class="mt-6 flex gap-2">
          <button
            class="line-height-[22px] btn h-10 w-full min-w-[129px] max-w-[129px]"
            type="button"
            on:click={handleContactUsBtnClick}
          >
            {$_('contact_us_text')}
          </button>
          <button
            type="submit"
            disabled={isLoading}
            class="line-height-[22px] btn h-10 w-full min-w-[129px] max-w-[129px]"
            on:click={() => {
              logComponentEvent({
                componentName: 'submit_age_screen_code',
                appAction: 'click',
                type: 'btn',
              })
            }}
          >
            {#if isLoading}
              <Spinner />
            {/if}
            <span>{$_('unlock_button_text')}</span>
          </button>
        </div>
      </form>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
