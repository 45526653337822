<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <circle cx="8" cy="8" r="6" fill="#E84741" />
  <mask
    id="mask0_5710_19228"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="2"
    y="2"
    width="12"
    height="12"
  >
    <rect x="2" y="2" width="12" height="12" fill="url(#pattern0_5710_19228)" />
  </mask>
  <g mask="url(#mask0_5710_19228)">
    <rect x="2" y="2" width="12" height="12" fill="white" />
  </g>
  <defs>
    <pattern
      id="pattern0_5710_19228"
      patternContentUnits="objectBoundingBox"
      width="1"
      height="1"
    >
      <use xlink:href="#image0_5710_19228" transform="scale(0.0138889)" />
    </pattern>
    <image
      id="image0_5710_19228"
      width="72"
      height="72"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAACE3AAAhNwEzWJ96AAABkklEQVR4nO3bsW3CUBSF4UOUPnS0jIAnCCNkgzACI7wRzAaMwAhkAocNaN0lE5ACJxDAHInC9xb/1+HbXP2SSYTeGx0OB6HfU/QC2RHIIJBBIINABoEMAhkEMghkEMggkEEg4zl6AUmaNGUsqZb01j3aSFq2VfmK2+ooRSAdg7yefX6XNJU0j1jm3Cj6545JU2aSmp5x1Vblc8h9LmX4Dho/OBtEhkCpEcggkEEgg0AGgQwCGQQyCGQQyCCQQSCDQAaBDAIZBDIIZBDIIJBBIINABoEMAhkEMghkZAi0f3A2iPBAbVX2klY3RqtuFir88MKvSVMWOjv+0lZlHbfNSZpAWYW/YtkRyCCQQSCDQAaBjBSnXLuDnLVOJ10/dDwGHHqAU0rwf1AXZyvp5WL0LWkeHSnDK1Z0HUfds+Wwq1zLEGh2ZzYdaok+GQKFXze4J0OgzZ3Zdqgl+mQIVEva3Xi+62ahwv+KSX+3fRb6f9tnneG2T4pAmWV4xVIjkEEgg0AGgQwCGQQyCGQQyCCQQSDjB7BcR5/lMIAOAAAAAElFTkSuQmCC"
    />
  </defs>
</svg>
