import { getContext, setContext } from 'svelte'
import { derived, writable, type Writable } from 'svelte/store'
import type { z } from 'zod'

export const defaultKey = 'form'

type Form = Record<string, { error: string; touched: boolean }>

export function createFormContext<T extends z.ZodObject<z.ZodRawShape>>({
  key,
  schema,
  refinedSchema,
  t,
}: {
  key?: string
  schema: T
  refinedSchema?: z.ZodTypeAny
  t: (key: string) => string
}) {
  const form = writable<Form>({})
  setContext(key || defaultKey, { form, schema })

  const allTouched = derived(form, ($formStore) =>
    Object.values($formStore).every((field) => field.touched),
  )
  const hasError = derived(form, ($formStore) =>
    Object.values($formStore).some((field) => field.error),
  )

  const validate = (formData: FormData) => {
    const result = (refinedSchema || schema).safeParse(
      Object.fromEntries(formData),
    )
    if (!result.success) {
      result.error.errors.forEach((error) => {
        for (const path of error.path) {
          form.update((form) => ({
            ...form,
            [path]: { touched: true, error: t(error.message) },
          }))
        }
      })
      throw new Error('Form data is invalid')
    }
    return result.data as z.infer<T>
  }

  return {
    form,
    allTouched,
    hasError,
    validate,
  }
}

export function getField({
  of,
  name,
  t,
}: {
  of?: string
  name: string
  t: (key: string) => string
}) {
  const context = getContext<{
    form: Writable<Form>
    schema: z.ZodObject<z.ZodRawShape>
  } | null>(of || defaultKey)

  if (!context) {
    throw new Error(
      '`getField` must be used within a `createFormStore` context',
    )
  }

  const { form, schema } = context

  form.update((form) => ({
    ...form,
    [name]: { touched: false, error: '' },
  }))

  const validate = (value: unknown) => {
    const result = (schema.shape[name] as z.ZodObject<z.ZodRawShape>).safeParse(
      value,
    )
    if (result.success) {
      clearError()
    } else {
      result.error.errors.forEach((err) => {
        setError(t(err.message))
      })
    }
  }

  const setError = (error: string) => {
    form.update((form) => {
      return {
        ...form,
        [name]: {
          touched: true,
          error,
        },
      }
    })
  }

  const clearError = () => {
    form.update((form) => {
      return {
        ...form,
        [name]: {
          ...form[name]!,
          error: '',
        },
      }
    })
  }

  const touch = () => {
    form.update((form) => {
      return {
        ...form,
        [name]: {
          touched: true,
          error: '',
        },
      }
    })
  }

  const remove = () => {
    form.update((form) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [name]: _, ...rest } = form
      return rest
    })
  }

  const error = derived(form, (form) => form[name]?.error)

  return {
    validate,
    touch,
    remove,
    error,
  }
}
