export const params = new URLSearchParams(window.location.search)

export const isStandalone = params.get('variant') === 'standalone'

export const version = params.get('version')

export const platform = params.get('platform')

export const language = params.get('language') || 'en'

export const region = params.get('region')

export const convertToken = params.get('convert_token')

export const audience = params.get('audience') as
  | null
  | 'seekrtech.forest'
  | 'seekrtech.sleeptown'
  | 'seekrtech.waterdo'
  | 'seekrtech.receipt'
  | 'upwardsware.forest'
  | 'upwardsware.waterdo'
  | 'upwardsware.receipt'
  | 'seekrtech.forest.next'
  | 'seekrtech.daily'

export const clientId = params.get('client_id')

export const redirectUri = params.get('redirect_uri') ?? ''

export const shouldLockServerSelect =
  params.get('should_lock_server_select') === 'true'

export const toBindingFlowIfLegacy = params.has('to_binding_flow_if_legacy')

export const disableSignup = params.has('disable_signup')
