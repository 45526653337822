import type { AuthCodeResponse } from '@/types/api'
import isNetworkError from 'is-network-error'
import ky, { HTTPError } from 'ky'
import { resolveURL } from './utils/url'

const httpClient = ky.create({
  headers: {
    'content-type': 'application/json',
  },
})

export async function peekUser(email: string) {
  try {
    await httpClient.get(resolveURL('/users/peek', { email }))
    return ['USER_NOT_FOUND'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 409:
          return ['USER_FOUND'] as const
        case 410:
          return ['LEGACY_USER_FOUND'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }

    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export type SignUpParams = Parameters<typeof signUp>[0]

export async function signUp({
  username,
  password,
  birthday,
  language,
  convertToken,
  ageScreenCode,
}: {
  username: string
  password: string
  birthday: string
  language: string // in the format of 'zh-TW'
  convertToken: string | null
  ageScreenCode?: string
}) {
  try {
    const response = await httpClient(
      // email is in the query string
      resolveURL(convertToken ? '/guests/convert' : '/signup'),
      {
        method: convertToken ? 'PUT' : 'POST',
        headers: {
          'accept-language': language,
        },
        json: {
          password,
          name: username,
          birthday,
          token: convertToken,
          age_screen_code: ageScreenCode,
        },
      },
    )
    const user = (await response.json()) as { id: string }
    return ['USER_CREATED', user] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        // When converting a guest on wrong server region
        case 403:
          return ['WRONG_TOKEN'] as const
        case 477:
          return ['INVALID_AGE_SCREEN_CODE'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function signIn(password: string) {
  try {
    await httpClient.post(resolveURL('/signin'), {
      json: { password },
    })
    return ['USER_SIGNED_IN'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 403:
          return ['WRONG_PASSWORD'] as const
        case 401:
          return [
            'EMAIL_NOT_VERIFIED',
            await error.response.json<{ id: string }>(),
          ] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function verifyGoogleIdToken({
  idToken,
  state,
}: {
  idToken: string
  state: string
}) {
  try {
    const response = await httpClient.post<AuthCodeResponse>(
      '/sso_callbacks/google',
      {
        json: {
          id_token: idToken,
          state,
        },
      },
    )
    if (response.redirected) {
      return ['REDIRECT', response.url] as const
    }
    throw new Error('Unexpected response')
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function verifyEmail(code: string) {
  try {
    await httpClient.put(resolveURL('/verify_code'), {
      json: { code },
    })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 403:
        case 404:
          return ['INVALID_CODE'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function resetPassword({
  email,
  newPassword,
  code,
}: {
  email: string
  newPassword: string
  code: string
}) {
  try {
    await httpClient.put('/reset_password', {
      json: {
        email,
        password: newPassword,
        token: code,
      },
    })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 403:
          return ['INVALID_CODE'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function grantAccess() {
  try {
    const response = await httpClient.post(resolveURL('/oauth/authorize'), {
      retry: 2,
    })
    return ['SUCCESS', await response.json<AuthCodeResponse>()] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      return ['UNHANDLED_HTTP_ERROR', code] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function signInAsGuest() {
  try {
    const response = await httpClient.post<AuthCodeResponse>(
      resolveURL('/guests'),
    )
    return ['SUCCESS', await response.json()] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      return ['UNHANDLED_HTTP_ERROR', code] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function changePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string
  newPassword: string
}) {
  try {
    await httpClient.put('/change_password', {
      json: {
        old_password: oldPassword,
        new_password: newPassword,
      },
    })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 403:
          return ['WRONG_PASSWORD'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', code] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function bindLegacyUser() {
  try {
    const response = await httpClient.put<AuthCodeResponse>(
      resolveURL('/bind_legacy_user'),
    )
    if (response.status === 204) {
      return ['ALREADY_BOUND'] as const
    }
    return ['SUCCESS', await response.json()] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      return ['UNHANDLED_HTTP_ERROR', code] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function changeEmail(data: { email: string; password: string }) {
  try {
    await httpClient.put(resolveURL('/change_email'), { json: data })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      const code = error.response.status
      switch (code) {
        case 403:
          return ['WRONG_PASSWORD'] as const
        case 409:
          return ['EMAIL_USED'] as const
        default:
          return ['UNHANDLED_HTTP_ERROR', { code }] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function changeUsername({
  userId,
  username,
  abortSignal,
}: {
  userId: string
  username: string
  abortSignal: AbortSignal
}) {
  try {
    await httpClient.put(resolveURL(`/users/${userId}`), {
      json: { name: username },
      signal: abortSignal,
    })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      return ['UNHANDLED_HTTP_ERROR', { code: error.response.status }] as const
    }
    if (error instanceof Error && error.name === 'AbortError') {
      return ['ABORTED'] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function sendVerificationEmail(
  url: string,
  acceptLanguage: string,
) {
  try {
    await httpClient.put(url, {
      headers: {
        'accept-language': acceptLanguage,
      },
    })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function deleteUser(data: { password: string }) {
  try {
    await httpClient.put('/delete_user', { json: data })
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      switch (error.response.status) {
        case 403:
          return ['WRONG_PASSWORD'] as const
        default:
          return [
            'UNHANDLED_HTTP_ERROR',
            { code: error.response.status },
          ] as const
      }
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}

export async function logOut() {
  try {
    await httpClient.delete('/logout')
    return ['SUCCESS'] as const
  } catch (error) {
    if (isNetworkError(error)) {
      return ['NETWORK_ERROR'] as const
    }
    if (error instanceof HTTPError) {
      return ['UNHANDLED_HTTP_ERROR', { code: error.response.status }] as const
    }
    return ['UNHANDLED_ERROR', error as Error] as const
  }
}
