<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  ><polyline
    points="15.5 5 8.5 12 15.5 19"
    fill="none"
    stroke="#666"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
  /></svg
>
