<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import BindStepEmail from '@/components/icons/bind-step-email.svelte'
  import ProgressBar from '@/components/progress-bar.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormContext } from '@/context/form'
  import { signIn } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { SignInForm } from '@/schema'
  import { _ } from 'svelte-i18n'

  let isLoading = false

  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')

  const { validate, allTouched, hasError } = createFormContext({
    schema: SignInForm,
    t: $_,
  })

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = validate(new FormData(event.currentTarget))

    isLoading = true
    const [result, payload] = await signIn(data.password)
    isLoading = false
    switch (result) {
      case 'USER_SIGNED_IN':
        dispatchWebViewAction({
          action: 'push',
          navBarTitle: '',
          navBarVisible: true,
          url: resolveURL('/bind').toString(),
        })
        break
      case 'EMAIL_NOT_VERIFIED':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/email_validation', {
            user_id: payload.id,
            from: '/signin',
          }).toString(),
          navBarTitle: $_('email_verification_title'),
          navBarVisible: true,
        })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'WRONG_PASSWORD':
        showDialog(Dialog.WrongPassword)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
    }
  }

  const messageParts = $_('signin_enter_password')
    .split('{email}')
    .join(`__${email}__`)
    .split('__')
</script>

<form
  class="flex h-full flex-col px-9 pb-[50px]"
  on:submit|preventDefault={handleSubmit}
>
  <ProgressBar currentStep={2} />
  <BindStepEmail />
  <div class="mt-6 flex-grow space-y-4">
    <h1 class="text-[24px] font-bold text-content-primary text-headline-md">
      {#each messageParts as part}
        {#if part === email}
          <span class="break-all text-[#65963E]">{part}</span>
        {:else}
          {part}
        {/if}
      {/each}
    </h1>
    <TextField
      type="password"
      name="password"
      dataFieldName="bind_signin_password"
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton />
    </div>
  </div>
  <div class="flex justify-center">
    <button
      class="btn"
      type="submit"
      disabled={isLoading || !$allTouched || $hasError}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </div>
</form>
