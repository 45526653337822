<script lang="ts">
  import { cn } from '@/lib/utils/cn'
  import { platform } from '@/params'
  import BackIcon from './icons/back.svelte'

  export let currentStep: number
  export let shouldHideNavBtn = false
  export let shouldSelfApplyPaddingX = false

  function handleBack() {
    history.back()
  }
</script>

<div
  class="relative mb-2 mt-[max(44px,env(safe-area-inset-top))] h-[58px] flex-shrink-0"
>
  {#if !shouldHideNavBtn && platform !== 'web'}
    <button
      type="button"
      class={cn(
        'absolute left-[-10px] top-[10px] size-6',
        shouldSelfApplyPaddingX && 'left-[26px]',
      )}
      on:click={handleBack}
    >
      <BackIcon />
    </button>
  {/if}
  <div class="absolute left-1/2 top-5 flex -translate-x-1/2 space-x-1">
    <div
      class={cn(
        'h-1 w-20 rounded bg-[#EDEEEF]',
        currentStep > 0 && 'bg-primary',
      )}
    ></div>
    <div
      class={cn(
        'h-1 w-20 rounded bg-[#EDEEEF]',
        currentStep > 1 && 'bg-primary',
      )}
    ></div>
    <div
      class={cn(
        'h-1 w-20 rounded bg-[#EDEEEF]',
        currentStep > 2 && 'bg-primary',
      )}
    ></div>
  </div>
</div>
